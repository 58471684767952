// Generated by Framer (4431e6b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {RmiuxlQ5N: {hover: true, pressed: true}};

const cycleOrder = ["RmiuxlQ5N"];

const variantClassNames = {RmiuxlQ5N: "framer-v-o0nstu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any; title?: string; link?: string; openInNewTab?: boolean }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "RmiuxlQ5N", tap: FBaL7CGe0, title: q19B9KUN_ = "Link Text", link: s5oK2jeby, openInNewTab: ldH0BcO0C = false, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "RmiuxlQ5N", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapsvnssb = activeVariantCallback(async (...args) => {
if (FBaL7CGe0) {
const res = await FBaL7CGe0(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-qkZ5T", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={s5oK2jeby} openInNewTab={ldH0BcO0C}><motion.a {...restProps} className={`${cx("framer-o0nstu", className)} framer-16826r4`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"RmiuxlQ5N"} onTap={onTapsvnssb} ref={ref} style={{...style}} {...addPropertyOverrides({"RmiuxlQ5N-hover": {"data-framer-name": undefined}, "RmiuxlQ5N-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO09idmlvdXNseSBSZWd1bGFy", "--framer-font-family": "\"Obviously Regular\", sans-serif", "--framer-font-size": "13px", "--framer-line-height": "120%", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Store Requirements</motion.p></React.Fragment>} className={"framer-1n8nddr"} fonts={["CUSTOM;Obviously Regular"]} layoutDependency={layoutDependency} layoutId={"SjeewZhcR"} style={{"--extracted-r6o4lv": "rgba(255, 255, 255, 0.6)", "--framer-paragraph-spacing": "0px"}} text={q19B9KUN_} variants={{"RmiuxlQ5N-hover": {"--extracted-r6o4lv": "rgba(255, 255, 255, 0.8)"}, "RmiuxlQ5N-pressed": {"--extracted-r6o4lv": "rgba(255, 255, 255, 0.54)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"RmiuxlQ5N-pressed": {children: <React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO09idmlvdXNseSBSZWd1bGFy", "--framer-font-family": "\"Obviously Regular\", sans-serif", "--framer-font-size": "13px", "--framer-line-height": "120%", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Link Text</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-qkZ5T [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qkZ5T .framer-16826r4 { display: block; }", ".framer-qkZ5T .framer-o0nstu { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-qkZ5T .framer-1n8nddr { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-qkZ5T .framer-v-o0nstu .framer-o0nstu { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qkZ5T .framer-o0nstu { gap: 0px; } .framer-qkZ5T .framer-o0nstu > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-qkZ5T .framer-o0nstu > :first-child { margin-top: 0px; } .framer-qkZ5T .framer-o0nstu > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 15.5
 * @framerIntrinsicWidth 65
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"esZiGOxit":{"layout":["auto","auto"]},"gAZYvR2zu":{"layout":["auto","auto"]}}}
 * @framerVariables {"FBaL7CGe0":"tap","q19B9KUN_":"title","s5oK2jeby":"link","ldH0BcO0C":"openInNewTab"}
 */
const FramerGoZn0gqVx: React.ComponentType<Props> = withCSS(Component, css, "framer-qkZ5T") as typeof Component;
export default FramerGoZn0gqVx;

FramerGoZn0gqVx.displayName = "Footer Link";

FramerGoZn0gqVx.defaultProps = {height: 15.5, width: 65};

addPropertyControls(FramerGoZn0gqVx, {FBaL7CGe0: {title: "Tap", type: ControlType.EventHandler}, q19B9KUN_: {defaultValue: "Link Text", displayTextArea: false, title: "Title", type: ControlType.String}, s5oK2jeby: {title: "Link", type: ControlType.Link}, ldH0BcO0C: {defaultValue: false, title: "Open in New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerGoZn0gqVx, [{family: "Obviously Regular", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/GoZn0gqVx:default", url: "assets/QXCC6HITqYj9vcduhtsg1xYfvA.woff"}, url: new URL("assets/QXCC6HITqYj9vcduhtsg1xYfvA.woff", import.meta.url).href}])